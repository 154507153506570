import { FunctionComponent } from 'react';
import { PageHeader } from '../../components/layout/pageHeader';
import { TextGrid } from '../../components/layout/textGrid';
import { MarkdownParser, TextBlock } from '../../components/layout/textBlock';
import { Container } from '../../components/layout/container';
import { Main } from '../../components/layout/main';
// import { useLanguage } from '../../store/slices/common/selectors';
// import { useGetLimitsQuery } from '../../store/api/limits';
import { Loader } from '../../components/common/loader';
import data from '../../translations/dictionary.json';

export const Limits: FunctionComponent = () => {
  // const lang = useLanguage();
  // const { data } = useGetLimitsQuery({ lang: lang || 'en' });

  if (!data) {
    return (
      <Main>
        <Loader />
      </Main>
    );
  }

  return (
    <Main>
      <PageHeader
        subtitle={data['limits-subtitle']}
        title={data['limits-title']}
      >
        <MarkdownParser text={data['limits-titleContent']} />
      </PageHeader>
      <Container>
        <TextGrid>
          <TextBlock text={data['limits-content']} markdown />
        </TextGrid>
      </Container>
    </Main>
  );
};
