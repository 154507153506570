import { Section, SectionTitle } from '../../../components/layout/section';
import { Translation } from '../../../translations/translation';
import { FunctionComponent } from 'react';
import styles from './partnerSection.module.css';
import { Container } from '../../../components/layout/container';

export const PartnerSection: FunctionComponent = () => {
  return (
    <Section
      title={
        <SectionTitle>
        </SectionTitle>
      }
      shade
    >
      <Container>
      </Container>
    </Section>
  );
};
