import { Container } from '../../components/layout/container';
import { Section } from '../../components/layout/section';
import styles from './index.module.css';
import { StepsBlock } from './stepsBlock';
import { HomeBlock } from './homeBlock';
import { FeaturesSection } from './featuresSection';
import { PricesSection } from './pricesSection';
import { PartnerSection } from './partnerSection';

export const Home = () => {
  return (
    <div>
      <div className={styles.hero}>
        <Container>
          <HomeBlock />
        </Container>
      </div>

      <FeaturesSection />
      {/* <LegalSection /> */}
      <PricesSection />
      <Section inverted>
        <Container>
          <StepsBlock />
        </Container>
      </Section>
      <PartnerSection />
    </div>
  );
};
