import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ROOT,
    prepareHeaders: (headers) => {
      // headers.set('access-control-allow-origin', `https://nor.devs.click`);
      // headers.set('origin', `https://nor.devs.click`);
      // headers.set('referer', `https://nor.devs.click`);

      return headers;
    },
  }),

  endpoints: () => ({}),
});
