import { Section, SectionTitle } from '../../../components/layout/section';
import { Translation } from '../../../translations/translation';
import { TitleDropdown } from '../../../components/common/titleDropdown';
import { Container } from '../../../components/layout/container';
import { PriceTable } from '../../../components/priceTable';
import { FunctionComponent, useEffect, useState } from 'react';
import { useLanguage } from '../../../store/slices/common/selectors';
import { useAppSelector } from 'store';
import { fiatsSelector } from 'store/slices/exchange/selectors';

export const PricesSection: FunctionComponent = () => {
  const lang = useLanguage();

  const fiats = useAppSelector(fiatsSelector);

  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    if (fiats.length) {
      setSelected(fiats[0].label);
    }
  }, [fiats]);

  if (!selected || !lang) {
    return null;
  }

  return (
    <Section
      title={
        <SectionTitle>
          <Translation k={'Cryptocurrency'} />
          <br />
          <Translation k={'for'} />{' '}
          <TitleDropdown
            options={fiats.map((currency) => {
              return {
                value: currency.label,
                label: currency.label,
              };
            })}
            value={selected}
            onChange={(value) => setSelected(value)}
          />
        </SectionTitle>
      }
    >
      <Container>
        <PriceTable fiat={selected} lang={lang} main />
      </Container>
    </Section>
  );
};
