/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  FunctionComponent,
  HTMLAttributes,
  useEffect,
  useState,
} from 'react';
import styles from './contactsForm.module.css';
import { Input } from '../../../components/common/input';
import { Select } from '../../../components/common/select';
import { Textarea } from '../../../components/common/textarea';
import { useTranslation } from '../../../translations/useTranslation';
import {
  useGetContactsQuery,
  useSendMessageMutation,
} from '../../../store/api/contacts';
import { useLanguage } from '../../../store/slices/common/selectors';
import { Translation } from '../../../translations/translation';

import dataJson from '../../../translations/dictionary.json';

export interface ContactsFormProps extends HTMLAttributes<HTMLDivElement> {}

export const ContactsForm: FunctionComponent<ContactsFormProps> = () => {
  const lang = useLanguage();
  const { data: contactsData } = useGetContactsQuery({ lang: lang || 'en' });
  const paymentIssueText = useTranslation('Payment issue');
  const yourEmailText = useTranslation('Your email');
  const yourMessageText = useTranslation('Your message');

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [paymentIssue, setPaymentIssue] = useState('');

  const [trigger, { error: sendError, isLoading, isSuccess }] =
    useSendMessageMutation();

  useEffect(() => {
    if (isSuccess) {
      setEmail('');
      setMessage('');
      setPaymentIssue('');
    }
  }, [isSuccess, setEmail, setMessage, setPaymentIssue]);

  if (!contactsData) {
    return null;
  }

  return (
    <form
      className={styles.contactsForm}
      onSubmit={(e) => {
        e.preventDefault();
        trigger({
          email,
          message,
          topic: paymentIssue,
          lang: lang || 'en',
        });
      }}
    >
      <div className={styles.contactsForm__header}>
        <a
          href={`tel:${dataJson['contacts-phone']}`}
          className={styles.contactsForm__link}
        >
          <span className={styles.contactsForm__icon}>
            <svg width={18} height={18} xmlns="http://www.w3.org/2000/svg">
              <use href="#phone" />
            </svg>
          </span>
          <span>{dataJson['contacts-phone']}</span>
        </a>
        <a
          href={`mailto:${dataJson['contacts-email']}`}
          className={styles.contactsForm__link}
        >
          <span className={styles.contactsForm__icon}>
            <svg width={20} height={16} xmlns="http://www.w3.org/2000/svg">
              <use href="#mail" />
            </svg>
          </span>
          <span>{dataJson['contacts-email']}</span>
        </a>
      </div>
      <div className={styles.contactsForm__grid}>
        {isSuccess ? (
          <div className={styles.contactsForm__success}>
            <Translation k={"We've received your message!"} />
          </div>
        ) : (
          <>
            <div className={styles.contactsForm__formColumn}>
              <Input
                type="text"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder={yourEmailText}
                //@ts-ignore
                errors={sendError?.data?.errors?.email as string[]}
              />
              <Select
                options={[
                  { label: paymentIssueText, value: '' },
                  ...contactsData.topics.map((topic) => ({
                    label: topic.topic,
                    value: topic.id,
                  })),
                ]}
                onChange={(event) => setPaymentIssue(event.target.value)}
                value={paymentIssue}
                //@ts-ignore
                errors={sendError?.data?.errors?.topic as string[]}
              />
              <Textarea
                placeholder={yourMessageText}
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                //@ts-ignore
                errors={sendError?.data?.errors?.message as string[]}
              />
            </div>
            <button
              className={styles.contactsForm__action}
              aria-label={'Send'}
              disabled={!email || !paymentIssue || !message || isLoading}
              type="submit"
            >
              <span className={styles.contactsForm__actionLabel}>
                Send message
              </span>
              <svg width={61} height={61} xmlns="http://www.w3.org/2000/svg">
                <use href="#submit" />
              </svg>
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.06915 15.2951L15.04 5.32422"
                  stroke="white"
                  strokeWidth="1.42441"
                  strokeLinecap="round"
                />
                <path
                  d="M5.15259 5.07422H15.3004V15.222"
                  stroke="white"
                  strokeWidth="1.42441"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </>
        )}
      </div>
    </form>
  );
};
