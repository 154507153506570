import { FunctionComponent } from 'react';
import { Section, SectionTitle } from '../../../components/layout/section';
import { Translation } from '../../../translations/translation';
import { CardGrid, CardGridItem } from '../../../components/layout/cardGrid';
import { FeatureCard } from '../../../components/cards/featureCard';
import starIcon from '../../../images/icons/star.svg';
import pillarIcon from '../../../images/icons/pillar.svg';
import stackIcon from '../../../images/icons/stack.svg';
import sircleIcon from '../../../images/icons/sircle.png';

export const FeaturesSection: FunctionComponent = () => {
  return (
    <Section
      title={
        <SectionTitle>
          <Translation k={'We constantly monitor the work of our service'} />
        </SectionTitle>
      }
      inverted
    >
      <CardGrid narrow>
        <CardGridItem>
          <FeatureCard
            title={<Translation k={'Immediate withdrawal'} />}
            icon={starIcon}
          >
            <p>
              <Translation
                k={
                  'We do not store your cryptocurrency, it is sent immediately to the specified address'
                }
              />
            </p>
          </FeatureCard>
        </CardGridItem>
        <CardGridItem>
          <FeatureCard
            title={<Translation k={'Fast because automatic'} />}
            icon={sircleIcon}
          >
            <p>
              <Translation
                k={'There is no need to wait for manual verification'}
              />
            </p>
          </FeatureCard>
        </CardGridItem>
        <CardGridItem>
          <FeatureCard
            title={<Translation k={'Multiple payment options'} />}
            icon={stackIcon}
          >
            <p>
              <Translation
                k={
                  'We accept debit and credit cards, SEPA bank transfers and multiple alternative payment methods'
                }
              />
            </p>
          </FeatureCard>
        </CardGridItem>
        <CardGridItem>
          <FeatureCard
            title={<Translation k={'We have the best exchange rate'} />}
            icon={pillarIcon}
          >
            <p>
              <Translation
                k={'Every day we go out to make the bet even lower'}
              />
            </p>
          </FeatureCard>
        </CardGridItem>
      </CardGrid>
    </Section>
  );
};
