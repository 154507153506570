import { FunctionComponent, HTMLAttributes } from "react";
import { TextBlock } from "../../../components/layout/textBlock";
import DOMPurify from 'dompurify';
import styles from "./termsSection.module.css";

export interface TermsSectionProps extends HTMLAttributes<HTMLDivElement> {
  section: {
    title: string;
    content: string;
    slug: string;
  };
  index: number;
}

export const TermsSection: FunctionComponent<TermsSectionProps> = ({
  section,
  index,
}) => {
  const sanitizedContent = DOMPurify.sanitize(section.content);

  return (
    <section
      className={styles.termsSection}
      id={`terms-${index}`}
      data-index={index}
    >
      {/*<h2 className={`h3 ${styles.termsSection__title}`}>{section.title}</h2>*/}
      {section.content.trim().startsWith('<') ? (
        <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
      ) : (
        <TextBlock text={section.content} markdown />
      )}
    </section>
  );
};