import React, { FunctionComponent, useState } from 'react';
import styles from './stepsBlock.module.css';
import { SectionTitle } from '../../../components/layout/section';
import step1 from '../../../images/steps/1.png';
import step2 from '../../../images/steps/2.png';
import step3 from '../../../images/steps/3.png';
import step1webp from '../../../images/steps/1.webp';
import step2webp from '../../../images/steps/2.webp';
import step3webp from '../../../images/steps/3.webp';
import { BigButtonLink } from '../../../components/common/bigButton';
import { Translation } from '../../../translations/translation';
import { useConfigData } from 'hooks/useConfigData';

export const StepsBlock: FunctionComponent = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { data } = useConfigData();

  const handleFocus = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div className={styles.stepsBlock}>
      <div className={styles.stepsBlock__header}>
        <SectionTitle className={styles.stepsBlock__title}>
          <Translation k={'How the exchange is carried out?'} />
        </SectionTitle>
      </div>
      <div className={styles.stepsBlock__image}>
        <picture>
          <source
            srcSet={(() => {
              switch (activeStep) {
                case 0:
                  return step1webp;
                case 1:
                  return step2webp;
                case 2:
                  return step3webp;
                default:
                  return step1webp;
              }
            })()}
            type="image/webp"
          />
          <source
            srcSet={(() => {
              switch (activeStep) {
                case 0:
                  return;
                case 1:
                  return step2;
                case 2:
                  return step3;
                default:
                  return step1;
              }
            })()}
            type="image/png"
          />
          <img
            src={(() => {
              switch (activeStep) {
                case 0:
                  return step1;
                case 1:
                  return step2;
                case 2:
                  return step3;
                default:
                  return step1;
              }
            })()}
            alt={''}
          />
        </picture>
      </div>
      <div className={styles.stepsBlock__content}>
        <ol className={styles.stepsBlock__list}>
          <li
            className={`${styles.stepsBlock__contentItem} ${
              activeStep === 0 ? styles.stepsBlock__contentItem_active : ''
            }`}
            tabIndex={0}
            onFocus={() => handleFocus(0)}
          >
            <div className={styles.stepsBlock__contentItemWrapper}>
              <h3
                className={`h3 h3_noMargin ${styles.stepsBlock__contentItemTitle}`}
              >
                <Translation k={'You choose the currency and payment method'} />
              </h3>
              <div className={` text`}>
                <p>
                  <Translation
                    k={
                      'Just choose your FIAT currency and amount you would like to spend on one side and your desired cryptocurrency on other side'
                    }
                  />
                </p>
              </div>
            </div>
            <div className={styles.stepsBlock__contentItemImage}>
              <img src={step1} alt={''} />
            </div>
          </li>
          <li
            className={`${styles.stepsBlock__contentItem} ${
              activeStep === 1 ? styles.stepsBlock__contentItem_active : ''
            }`}
            tabIndex={0}
            onFocus={() => handleFocus(1)}
          >
            <div className={styles.stepsBlock__contentItemWrapper}>
              <h3
                className={`h3 h3_noMargin ${styles.stepsBlock__contentItemTitle}`}
              >
                <Translation k={'Pass account verification'} />
              </h3>
              <div className={` text`}>
                <p>
                  <Translation
                    k={
                      'Upload identity document passport driver s license. And complete liveliness check. Facial verification on camera'
                    }
                  />
                </p>
              </div>
            </div>
            <div className={styles.stepsBlock__contentItemImage}>
              <img src={step2} alt={''} />
            </div>
          </li>
          <li
            className={`${styles.stepsBlock__contentItem} ${
              activeStep === 2 ? styles.stepsBlock__contentItem_active : ''
            }`}
            tabIndex={0}
            onFocus={() => handleFocus(2)}
          >
            <div className={styles.stepsBlock__contentItemWrapper}>
              <h3
                className={`h3 h3_noMargin ${styles.stepsBlock__contentItemTitle}`}
              >
                <Translation k={'Receive cryptocurrency'} />
              </h3>
              <div className={` text`}>
                <p>
                  <Translation
                    k={
                      'Proceed with your payment and receive cryptocurrency to your defined wallet address'
                    }
                  />
                </p>
              </div>
            </div>
            <div className={styles.stepsBlock__contentItemImage}>
              <img src={step3} alt={''} />
            </div>
          </li>
        </ol>
        {data?.createAccountLink ? (
          <BigButtonLink
            href={data.createAccountLink.path}
            target={data.createAccountLink.target}
            rel={
              data.createAccountLink.target === '_blank'
                ? 'noopener noreferer'
                : ''
            }
          >
            <span>
              <Translation k={'Sing up and start'} />
            </span>
            <svg width={25} height={16} xmlns="http://www.w3.org/2000/svg">
              <use href="#arrowRight" />
            </svg>
          </BigButtonLink>
        ) : null}
      </div>
    </div>
  );
};
