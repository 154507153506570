import { FunctionComponent, HTMLAttributes } from 'react';
import styles from './textBlock.module.css';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import remarkGfm from 'remark-gfm';

export interface TextBlockProps extends HTMLAttributes<HTMLDivElement> {
  text?: string;
  markdown?: boolean;
}

export const MarkdownParser: FunctionComponent<{ text?: string }> = ({
  text,
}) => {
  if (!text) {
    return null;
  }
  return (
    <ReactMarkdown
      components={{
        a({ children, href, ...rest }) {
          if (!href?.includes(':') && href) {
            return (
              <Link to={href} {...rest}>
                {children}
              </Link>
            );
          }
          return (
            <a
              href={`${href}`}
              target="_blank"
              rel="noopener noreferrer"
              {...rest}
            >
              {children}
            </a>
          );
        },
        table: ({ children }) => (
          <div className={styles.textBlock__table}>
            <table>{children}</table>
          </div>
        ),
      }}
      remarkPlugins={[remarkGfm]}
    >
      {text}
    </ReactMarkdown>
  );
};

export const TextBlock: FunctionComponent<TextBlockProps> = ({
  text,
  markdown,
  children,
}) => {
  if (!text) {
    return null;
  }
  if (markdown) {
    return (
      <div className={`${styles.textBlock} text`}>
        <MarkdownParser text={text} />
      </div>
    );
  }
  return (
    <div
      className={`${styles.textBlock} text`}
      dangerouslySetInnerHTML={text ? { __html: text } : undefined}
    >
      {!text ? children : null}
    </div>
  );
};
