import React, { FunctionComponent, useState, useEffect } from 'react';
import { PageHeader } from '../../components/layout/pageHeader';
import { TextGrid } from '../../components/layout/textGrid';
import { MarkdownParser } from '../../components/layout/textBlock';
import { Container } from '../../components/layout/container';
import { Main } from '../../components/layout/main';
import { TermsSection } from './termsSection';
import styles from './terms.module.css';
import { Error } from '../error';

import data from '../../translations/dictionary.json';

interface TermsSection {
  slug: string;
  title: string;
  content: string;
}

export const Terms: FunctionComponent = () => {
  const [sections, setSections] = useState<TermsSection[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentSlug, setCurrentSlug] = useState<string | null>(null);

  useEffect(() => {
    const loadContent = async () => {
      try {
        console.log("Найденные разделы:");
        const loadedSections = await Promise.all(
          data['terms-sections'].map(async (section, index) => {
            console.log(`${index + 1}. ${section.title} (slug: ${section.slug})`);
            try {
              const filePath = `/content${section.slug}.html`;
              console.log(`Загрузка файла: ${filePath}`);
              const response = await fetch(filePath);
              if (!response.ok) {
                console.log(`Ошибка при загрузке ${filePath}: ${response.status}`);
                return { ...section, content: '' };
              }
              const content = await response.text();
              console.log(`Успешно загружен файл: ${filePath}`);
              return { ...section, content };
            } catch (error) {
              console.error(`Ошибка при загрузке контента для ${section.slug}`, error);
              return { ...section, content: '' };
            }
          })
        );
        setSections(loadedSections);
        setLoading(false);
        console.log('Загруженные разделы:', loadedSections);
      } catch (error) {
        console.error('Ошибка при загрузке контента:', error);
        setLoading(false);
      }
    };

    loadContent();
  }, []);

  useEffect(() => {
    const handleUrlChange = () => {
      const path = window.location.pathname;
      const slugFromPath = path.split('/').filter(Boolean).pop() || '';
      
      if (sections.some(section => section.slug === slugFromPath)) {
        setCurrentSlug(slugFromPath);
      } else if (sections.length > 0) {
        // Если slug не найден, устанавливаем первый раздел по умолчанию
        setCurrentSlug(sections[0].slug);
        // Обновляем URL без перезагрузки страницы
      }
    };

    handleUrlChange();
    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [sections]);

  if (loading) {
    return <Main><div></div></Main>;
  }

  if (sections.length === 0) {
    return <Main><div></div></Main>;
  }

  const currentSection = sections.find(section => section.slug === currentSlug) || sections[0];

  console.log('Текущий раздел:', currentSection);

  if (!currentSection) {
    return <Error code={404} />;
  }

  const handleSectionChange = (slug: string) => {
    const newUrl = `${slug}`;
    window.history.pushState({}, '', newUrl);
    setCurrentSlug(slug);
  };

  return (
    <Main>
      <PageHeader subtitle={data['terms-subtitle']} title={currentSection.title}>
        <MarkdownParser text={data['terms-titleContent']} />
      </PageHeader>
      <Container>
        <TextGrid
          aside={
            <ul className={styles.terms__nav}>
              {sections.map((navSection, index) => (
                <li
                  key={index}
                  className={`${styles.terms__navItem} ${
                    navSection.slug === currentSlug ? styles.terms__navItem_active : ''
                  }`}
                >
                  <span
                    className={styles.terms__navLink}
                    onClick={() => handleSectionChange(navSection.slug)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleSectionChange(navSection.slug);
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    {navSection.title}
                  </span>
                </li>
              ))}
            </ul>
          }
        >
          <TermsSection 
            section={currentSection} 
            index={0} 
            key={currentSection.slug} 
          />
        </TextGrid>
      </Container>
    </Main>
  );
};