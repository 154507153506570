import { FunctionComponent } from 'react';
import styles from './footerLogos.module.css';
import { useConfigData } from 'hooks/useConfigData';

export const FooterLogos: FunctionComponent = () => {
  const { data } = useConfigData();
  if (!data) return null;
  return (
    <div className={styles.footerLogos}>
      {data.paymentMethods.map((item, index) => (
        <img src={item} key={index} alt={''} />
      ))}
    </div>
  );
};
